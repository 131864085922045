// Generated by Framer (edb532b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["UygD0pvuW", "ah6Jyd0lS", "ARwyYHjmK"];

const serializationHash = "framer-CC0MN"

const variantClassNames = {ah6Jyd0lS: "framer-v-7mwlme", ARwyYHjmK: "framer-v-1vfyn86", UygD0pvuW: "framer-v-189ob0v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 2": "ah6Jyd0lS", "Variant 3": "ARwyYHjmK", Image: "UygD0pvuW"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, CtLG2LLxu: image ?? props.CtLG2LLxu, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "UygD0pvuW"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CtLG2LLxu, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "UygD0pvuW", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-189ob0v", className, classNames)} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"UygD0pvuW"} ref={ref ?? ref1} style={{backdropFilter: "blur(5px)", backgroundColor: "rgba(102, 112, 255, 0.05)", borderBottomLeftRadius: 48, borderBottomRightRadius: 48, borderTopLeftRadius: 48, borderTopRightRadius: 48, WebkitBackdropFilter: "blur(5px)", ...style}} {...addPropertyOverrides({ah6Jyd0lS: {"data-framer-name": "Variant 2"}, ARwyYHjmK: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: `max(${componentViewport?.width || "100vw"} - 48px, 1px)`, ...toResponsiveImage(CtLG2LLxu)}} className={"framer-xnjvis"} data-framer-name={"Photo 1"} layoutDependency={layoutDependency} layoutId={"FNY75LeMD"} style={{borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24}} {...addPropertyOverrides({ah6Jyd0lS: {background: {alt: "", fit: "fill", sizes: "697px", ...toResponsiveImage(CtLG2LLxu)}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CC0MN.framer-1309wgk, .framer-CC0MN .framer-1309wgk { display: block; }", ".framer-CC0MN.framer-189ob0v { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 24px; position: relative; width: 1128px; will-change: var(--framer-will-change-override, transform); }", ".framer-CC0MN .framer-xnjvis { aspect-ratio: 1.5060240963855422 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 717px); overflow: visible; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-CC0MN.framer-189ob0v { gap: 0px; } .framer-CC0MN.framer-189ob0v > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-CC0MN.framer-189ob0v > :first-child { margin-left: 0px; } .framer-CC0MN.framer-189ob0v > :last-child { margin-right: 0px; } }", ".framer-CC0MN.framer-v-7mwlme .framer-xnjvis { flex: none; height: var(--framer-aspect-ratio-supported, 463px); width: 697px; }", ".framer-CC0MN.framer-v-1vfyn86.framer-189ob0v { width: 358px; }", ".framer-CC0MN.framer-v-1vfyn86 .framer-xnjvis { height: var(--framer-aspect-ratio-supported, 206px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 765
 * @framerIntrinsicWidth 1128
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"ah6Jyd0lS":{"layout":["fixed","auto"]},"ARwyYHjmK":{"layout":["fixed","auto"]}}}
 * @framerVariables {"CtLG2LLxu":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLbIiEwjhg: React.ComponentType<Props> = withCSS(Component, css, "framer-CC0MN") as typeof Component;
export default FramerLbIiEwjhg;

FramerLbIiEwjhg.displayName = "Project Photo Card";

FramerLbIiEwjhg.defaultProps = {height: 765, width: 1128};

addPropertyControls(FramerLbIiEwjhg, {variant: {options: ["UygD0pvuW", "ah6Jyd0lS", "ARwyYHjmK"], optionTitles: ["Image", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, CtLG2LLxu: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerLbIiEwjhg, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})